// import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import ModelList from "../../components/ModelList"
import React from "react"
import Seo from "../../components/seo"

const RelatedPage = () => {
 
  const ibanez = null;
  const starfield = null;
  const others = null;

  return (
    <Layout >
      <Seo title="Talman related guitars" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">Related<br /> guitars</h1>
        
        <section className="flex flex-col items-center">
          <h2 className="text-2xl mt-28">coming soon...</h2>
        </section>

        <section id="ibanez" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Ibanez <a className="hash-link" href="#ibanez">#</a>
          </h2>
          <ModelList list={ibanez} />
        </section>

        <section id="starfield" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Starfield <a className="hash-link" href="#starfield">#</a>
          </h2>
          <ModelList list={starfield} />
        </section>

        <section id="others" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Others <a className="hash-link" href="#others">#</a>
          </h2>
          <ModelList list={others} />
        </section>
      </div>
    </Layout>
  )
}

export default RelatedPage